<template>
  <v-container class="grey lighten-5 min-h-100 px-0" fluid v-if="getRole">
    <v-row no-gutters v-if="getRole.id">
      <v-col md="10" cols="12" sm="8" xs="12" class="mx-auto">
        <v-card-title>
          Company Type
          <v-spacer></v-spacer>
          <v-btn
            v-if="getRole.id == superadmin"
            depressed
            elevation="1"
            color="primary"
            @click="goToAddCompanyType"
            >Add Company Type</v-btn
          >
        </v-card-title>
        <vue-good-table
          :columns="headers"
          :rows="getCompaniesType"
          :line-numbers="true"
          styleClass="vgt-table striped"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [10, 20, 50, 100],
            dropdownAllowAll: true,
            setCurrentPage: 1,
            jumpFirstOrLast: false,
            firstLabel: 'First',
            lastLabel: 'Last',
            nextLabel: 'Next',
            prevLabel: 'Prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
            infoFn: (params) =>
              `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
          }"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            placeholder: 'Search',
          }"
          @on-row-click="clickRow"
        >
          <div slot="emptystate">No Data Found.</div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'created'">
              {{ formatDate(props.row.created) }}
            </span>
          </template>
        </vue-good-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'CompanyType',
  data() {
    return {
      search: '',
      drawer: true,
      headers: [
        { label: 'id', align: 'start', sortable: true, field: 'id' },
        { label: 'Name', field: 'name', sortable: true },
        { label: 'Created DateTime', field: 'created', sortable: true },
      ],
    };
  },
  watch: {
    getRole(r) {
      if (r?.id == this.superadmin) {
      } else {
        this.$router.replace('/');
      }
    },
  },
  computed: {
    ...mapGetters(['getCompaniesType', 'getRole', 'getSearchText']),
    ...mapState({
      admin: (state) => state.admin,
      hradmin: (state) => state.hradmin,
      superadmin: (state) => state.superadmin,
    }),
  },
  created() {
    if (this.getRole?.id == this.superadmin) {
      this.$store.dispatch('fetchCompaniesType', { self: this });
    } else {
      this.$router.replace('/');
    }
  },
  methods: {
    clickRow(params) {
      if (this.getRole.id == this.superadmin) {
        this.$router.push(`/edit-company-type/${params.row.id}`);
      }
    },
    goToAddCompanyType() {
      this.$router.push('/add-company-type');
    },
    formatDate(value) {
      if (value) {
        return format(value.seconds * 1000, 'dd MMM yyyy hh:mm a');
      }
    },
  },
  mounted() {
    if (this.getRole?.id != this.superadmin) {
      this.$router.push('/');
      return;
    }
    if (this.getCompaniesType && this.getCompaniesType.length) {
      this.companiesType = this.getCompaniesType;
    } else {
      this.companiesType = [];
    }
  },
};
</script>

<style></style>
